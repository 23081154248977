.testimonial {
  padding: 100px;
  background-color: #F3F5FC;
}

.testimonial-title {
  font-weight: 600;
  font-size: 66px;
  line-height: 100px;
  margin-bottom: 72px;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 32px; 
}

.testimonials-list {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px;
  transition: 0.3s ease-in-out;
}

.testimonials-list:hover {
  transform: translateY(-2%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.01), 0 6px 20px 0 rgba(0, 0, 0, 0.10);
}

.user-testimonial {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #0A1329;
  margin-bottom: 28px;  
}

.testimonial-user-profile {
  display: flex;
  align-items: center;
}

.testimonial-user-name-job {
  margin-left: 16px;
}

.testimonial-user-name-job > h1 {
  color: #0A1329;  
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 4px;
}

.testimonial-user-name-job > p {
  color: #0A1329;
  opacity: 0.55;
  font-weight: 400;
  font-size: 18px;
}

@media only screen and (max-width: 1000px) {
  .testimonial {
    padding: 100px 24px;    
  }
}

/* Mobile */
@media only screen and (max-width: 640px) {
  .testimonial {
    padding: 100px 24px;    
  }

  .testimonial-title {
    font-weight: 600;
    font-size: 48px;
    line-height: 76px;
    margin-bottom: 72px;
  }

  .testimonials-grid {    
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 32px; 
  }
}