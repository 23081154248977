.hero {
  display: flex;
  background-color: #F3F5FC;
  padding: 60px 100px 144px;  
}

.hero-description {
  display: flex;
  flex-direction: column;
  width: 471px;
  margin-right: 40px;
}

.discount-tag {
  height: 36px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFEBEE;  
  padding: 4px 16px;
  border-radius: 50px; 
  margin-bottom: 16px;
}

.discount-tag > p {
  font-weight: 500;
  font-size: 16px;
  color: #FF645A; 
  margin-left: 16px;
}

.hero-description-heading {
  font-weight: 600;
  font-size: 66px;
  line-height: 86px;
  color: #0A1329;
  margin-bottom: 18px;
}

.hero-description-paragraph {
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
  color: #0A1329;
  opacity: 0.55;
}

.hero-description-button {
  width: 220px;
  justify-content: center;  
  margin-top: 40px;
}

.hero-description-button > a {
  display: flex;
  padding: 16px 38px;
  color: #fff;
  background-color: #315BC7;  
  border-radius: 50px;
  font-weight: 500;
  font-size: 18px;
  transition: 0.3s;
}

.hero-description-button > a:hover {
  background-color: #0539bd;
}

.hero-image {
  margin-top: 28px;
}

.hero-image > img {
  width: 748px;
}

@media only screen and (max-width: 1024px) {
  .hero {
    padding: 24px 24px 96px;
    /* flex-direction: column-reverse; */
  }

  .hero-image > img {
    margin-top: 32px;
    width: 100%;
  }

  .discount-tag {
    height: 28px;
    width: 172px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFEBEE;  
    padding: 4px 16px;
    border-radius: 50px; 
    margin-bottom: 16px;
  }
  
  .discount-tag > p {
    font-weight: 500;
    font-size: 14px;
    color: #FF645A; 
    margin-left: 16px;
  }

  .hero-description-heading {
    font-weight: 600;
    font-size: 56px;
    line-height: 64px;
  }

  .hero-description-paragraph {    
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #0A1329;
    opacity: 0.55;
  }
}

/* Mobile */
@media only screen and (max-width: 640px) {
  .hero {
    padding: 24px 24px 96px;
    flex-direction: column-reverse;
  }

  .hero-image {
    margin: 8px 0px 32px;
  }

  .hero-image > img {
    width: 107%;
  }

  .discount-tag {
    height: 28px;
    width: 172px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFEBEE;  
    padding: 4px 16px;
    border-radius: 50px; 
    margin-bottom: 16px;
  }
  
  .discount-tag > p {
    font-weight: 500;
    font-size: 14px;
    color: #FF645A; 
    margin-left: 16px;
  }

  .hero-description {
    width: 100%;
    margin-right: 0px;
  }

  .hero-description-heading {
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
  }

  .hero-description-paragraph {    
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #0A1329;
    opacity: 0.55;
  }
}

