.benefit-container {
  position: relative;
}

.benefit {
  width: full;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #F3F5FC;
  padding-bottom: 332px; 
}

.benefit-description {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 52px;
}

.benefit-description > h1 {
  font-weight: 600;
  font-size: 48px;
  color: #0A1329;
  margin-top: 96px;
  margin-bottom: 28px;
}

.benefit-description > p {
  font-weight: 400;
  font-size: 24px;
  line-height: 44px;
  color: #0A1329;
  opacity: 0.55;
  width: 645px;
  margin: 0px auto 18px;
}

.benefit-table {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));  
  text-align: center;
  width: 748px;
  margin: 0px auto 78px;
}

.benefit-table > div > h1 {
  color: #0A1329;  
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 20px;
}

.benefit-table > div > p {
  color: #0A1329;
  opacity: 0.55;
  font-weight: 400;
  font-size: 20px;
}

.benefit-blank {
  height: 188px;
  width: full;
  clear: both;
}

.benefit-blank > img {
  display: block;    
  position:absolute;
  text-align: center;  
  left:0;
  right:0;
  bottom:0;
  margin:auto;
}

/* Mobile */
@media only screen and (max-width: 640px) {
  .benefit {
    padding: 0px 24px 160px;
  }

  .benefit-description {
    width: 100%;
    margin-bottom: 52px;
  }

  .benefit-description > p {    
    width: 100%;       
    font-size: 24px;
    line-height: 34px; 
    text-align: center;
  }

  .benefit-table {    
    grid-template-columns: repeat(2, minmax(0, 1fr));  
    row-gap: 48px;
    text-align: center;
    width: 100%;    
  }

  .benefit-blank > img {
    width: 80%;
    display: block;    
    position:absolute;
    text-align: center;  
    left:0;
    right:0;
    bottom:0;
    margin:auto;
  }
}