.success-checkout {
  display: flex;
  justify-content: center;
  background-color: #F3F5FC;
  padding: 24px 100px 96px;
}

.success-checkout-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 30px;
  padding: 20px 0px 24px;
}

.success-checkout-container-image {
  display: flex;  
  justify-content: center;
}

.success-checkout-container-image > img {
  display: flex;
  justify-self: center;    
  width: 320px;
  margin-bottom: 20px;
}

.success-checkout-container > h1 {
  font-weight: 500;
  font-size: 32px;  
  color: #0A1329;
  text-align: center;
  margin-bottom: 26px;
}

.payment-container {
  width: 37%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 16px;
}

.payment-method {
  display: flex;
  justify-content: space-between;
  color: #0A1329;
  font-size: 18px;
  margin-bottom: 16px;
}

.payment-method-info {
  font-weight: 500;
}

.check-payment-status {
  display: flex;
  justify-content: center;
}

.check-payment-status > a {
  padding: 14px 36px;
  color: #fff;
  background-color: #315BC7;  
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  transition: 0.3s;
}

.check-payment-status > a:hover {
  background-color: #0539bd;
}

@media only screen and (max-width: 1000px) {
  .success-checkout {    
    padding: 24px 24px 96px;
  }

  .success-checkout-container {    
    padding: 20px 0px 24px;
  }
}

/* Mobile */
@media only screen and (max-width: 640px) {
  .success-checkout {    
    padding: 24px 24px 96px;
  }

  .success-checkout-container {    
    padding: 20px 0px 24px;
  }

  .success-checkout-container-image > img {
    width: 240px;    
  }

  .success-checkout-container > h1 {    
    font-size: 28px;      
  }

  .payment-container {
    width: 80%;    
  }

  .payment-method {
    font-size: 16px;    
  }
}
