.checkout {
  display: flex;  
  padding: 24px 100px 112px;
  background-color: #F3F5FC;
}

.checkout-detail {
  width: 70%;
  background-color: #fff;
  border-radius: 30px;
  padding: 28px 32px;
  margin-right: 32px;
}

.checkout-total {
  height: 100%;
  width: 30%;
  background-color: #fff;
  border-radius: 30px;
  padding: 28px 32px;
}

.checkout-title {
  font-weight: 500;
  font-size: 24px;
  color: #0A1329;
  margin-bottom: 24px;
}

.checkout-product-list {
  display: flex;    
  margin-bottom: 32px;
}

.checkout-product-list-images-name {
  display: flex;  
  flex-grow: 1;
  margin-right: 40px;
}

.checkout-product-list-image {
  width: 220px;
  border-radius: 20px;
}

.checkout-product-list-name {
  display: flex;
  flex-direction: column;  
  justify-content: space-between;  
  width: 100%;
  margin-left: 24px;
  padding: 12px 0px;
}

.checkout-product-list-name > h1 {
  font-weight: 500;
  font-size: 28px;
  color: #0A1329;
}

.checkout-product-list-name > p {
  font-size: 18px;
  color: #0A1329;
  opacity: 0.55;
  margin-right: 10px;
}

.checkout-product-list-price {  
  width: 20%;
  padding: 20px 0px;
  font-size: 18px;
  color: #0A1329;
  opacity: 0.55;
}

.checkout-input-form {
  display: flex;
  flex-direction: column;
  color: #0A1329;
  font-weight: 300;
  font-size: 18px;
}

.checkout-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.checkout-input {
  margin-top: 8px;
  padding: 10px 12px;
  border: 1px solid #c5c7ca;
  border-radius: 10px;
  color: #0A1329; 
  font-family: 'Rubik', sans-serif; 
  font-weight: 300;
  font-size: 18px;
  transition: 0.3s ease-in-out;
}

.checkout-input:focus {
  border: 1px solid #306bc5;
  outline-width: 0;
}

.checkout-button {
  background-color: #0A1329;
  color: #fff;
  font-weight: 300;
  font-family: 'Rubik', sans-serif; 
  font-size: 18px;
  border-radius: 50px;
  border: 0px;
  padding: 14px 0px;
  margin-top: 12px;
  transition: 0.3s ease-in-out;
}

.checkout-button:hover {
  cursor: pointer;
  background-color: #081022;
}

.save-address-button {
  display: flex;
  flex-direction: row-reverse;
}

.save-address-button > input {  
  font-weight: 600;
  font-size: 16px;
  background-color: #315BC7;
  color: #fff;
  border: 0px;
  border-radius: 10px;
  margin-top: 12px;
  padding: 12px 40px;
  margin-right: 12px;
  transition: 0.3s ease-in-out;
  cursor: pointer; 
}

.save-address-button > input:hover {
  background-color: #0539BD;
}

.checkout-subtotal-items {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.checkout-subtotal-costs {
  color: #0A1329;
  font-weight: 500;
}

.checkout-total-pay {
  display: flex;
  justify-content: space-between;
  color: #081022;
  font-weight: 600;
  margin-bottom: 12px;
  padding-top: 12px;
  border-top: 1px solid #0A1329;
}

.empty-checkout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #F3F5FC;
  padding-bottom: 64px;
}

.empty-checkout > img {
  height: 360px;
  margin: 24px auto 32px;  
}

.empty-checkout > h1 {
  color: #0A1329;
  font-weight: 600; 
  font-size: 36px;
  text-align: center;  
}

@media only screen and (max-width: 1000px) {
  .checkout {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 112px;
  }

  .checkout-detail {
    width: auto;    
    padding: 24px;
    margin-right: 0px;
  }

  .checkout-total {
    width: auto;
    height: auto;
    padding: 24px;
    margin-top: 32px; 
  }
}

/* Mobile */
@media only screen and (max-width: 640px) {
  .checkout {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 112px;
  }

  .checkout-detail {
    width: auto;    
    padding: 24px;
    margin-right: 0px;
  }

  .checkout-product-list-images-name {
    display: flex;  
    align-items: center;
    flex-grow: 0;
    margin: 0px;
  }

  .checkout-product-list-image {
    width: 96px;
    height: 52px;
    object-fit: cover;
    border-radius: 20px;
  }

  .checkout-total {
    width: auto;
    height: auto;
    padding: 24px;
    margin-top: 32px; 
  }

  .checkout-product-list {
    display: flex;    
    flex-direction: column;
  }

  .checkout-product-list-name > h1 {
    font-weight: 500;
    font-size: 20px;
    color: #0A1329;
  }

  .checkout-product-list-name > p {
    font-size: 16px;
    color: #0A1329;
    opacity: 0.55;    
  }

  .checkout-product-list-price {
    margin-left: 43%;
    width: auto;    
    padding: 12px 0px;
    font-size: 16px;
    color: #0A1329;
    opacity: 0.55;
  }
}