.configurator {
  padding: 60px 100px 112px;
  background-color: #F3F5FC;
  font-family: 'Rubik', sans-serif;
  padding-inline: 10%;
}

.configurator-title {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.configurator-title > h1 {
  color: #0A1329;
  font-weight: 600;
  font-size: 48px;
  text-align: left;
  margin-bottom: 20px;
}

.configurator-title > p {
  color: #0A1329;
  opacity: 0.55;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  text-align: left;  
}

.quesion-answers {
  padding: 20px;
  display: flex;
  gap: 10px;
}

.yes {
  color: #fff;
  background-color: #315BC7;
  font-weight: 500;
  font-size: 16px;
  padding: 12px 20px;
  border-radius: 50px;
  transition: 0.3s;
  border: none;
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
}

.no {
  color: #315BC7;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  padding: 12px 20px;
  border-radius: 50px;
  transition: 0.3s;
  border: none;
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
}

.all-modules-list {
  padding: 30px;
  padding-left: 0px;
  display: flex;
  gap: 15px;
}

.selected-modules {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin-top: 30px
}

.selected-module-list {
  display: flex;
  gap: 15px;
}

.buy-or-test {
  display: flex;
  gap: 10px;
  align-items: center;
  padding-top: 20px;
}

.buy {
  color: #fff;
  background-color: #315BC7;
  font-weight: 500;
  font-size: 16px;
  padding: 12px 20px;
  border-radius: 50px;
  transition: 0.3s;
  border: none;
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
  margin-right: 20px;
}

.test {
  color: #315BC7;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  padding: 12px 20px;
  border-radius: 50px;
  transition: 0.3s;
  border: none;
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
}

/*product-card-------------------------------------------------------*/

.product-card {
  width: 300px;
  height: 500px;
  background-color: white;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 10px;
  padding: 10px;
  position: relative;
}

.product-card-title {
  display: flex;
  padding: 5px;
  align-items: center;
  font-size: larger;
}

.product-card-feedboost-logo {
  width: 25px;
  margin-right: 5px;
}

.product-card-image {
  width: calc(100% - 40px);
  margin-left: 10px;
}

.product-card-image-gray {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.product-card-description {
  padding-inline: 10px;
  color: #818181;
}

.product-card-price {
  position: absolute;
  left: 30px;
  bottom: 80px;
  font-style: italic;
  color: #818181;
}

.product-card-learn-more {
  position: absolute;
  left: 20px;
  bottom: 20px;
  color: #315BC7;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 50px;
  transition: 0.3s;
  border: none;
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
}

.product-card-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.product-card-button-add {
  color: #fff;
  background-color: #315BC7;
  font-weight: 500;
  font-size: 14px;
  padding: 12px 20px;
  border-radius: 50px;
  transition: 0.3s;
  border: none;
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
}

.product-card-button-remove {
  color: #315BC7;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 50px;
  transition: 0.3s;
  border: none;
  cursor: pointer;
  font-family: 'Rubik', sans-serif;
}

@media only screen and (max-width: 1000px) {
  .gallery {
    padding: 60px 24px 112px;    
  }

  .gallery-list-item-image-container > img {    
    height: 180px;    
  }
  
  .gallery-list-item-image-overlay {
    height: 180px;    
  }
}

/* Mobile */
@media only screen and (max-width: 640px) {
  .gallery {
    padding: 60px 24px 112px;    
  }

  .gallery-list {    
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .gallery-list-item-image-container > img {    
    height: 180px;    
  }
  
  .gallery-list-item-image-overlay {
    height: 180px;    
  }
}