.all-products {
  padding: 60px 100px 112px;
  background-color: #F3F5FC;
  font-family: 'Rubik', sans-serif;
}

.all-products-title {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.all-products-title>h1 {
  color: #0A1329;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  margin-bottom: 20px;
}

.all-products-title>p {
  color: #0A1329;
  opacity: 0.55;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
}

.title {
  display: flex;
}

.illustration {
  height: 400px;
}

.icon {
  width: 50px;
  height: 50px;
}

.image {
  width: 400px;
  height: 400px;
  border-radius: 10px;
}

.title-text {
  font-size: 30px;
}

.what-is {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.main {
  display: flex;
  padding-inline: 15%;
  gap: 30px;
  flex-direction: column;
}

.module {
  flex: 1;
  padding: 30px;
  padding-bottom: 0px;
  display: flex;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 12px 2px rgb(216, 218, 224);
  -moz-box-shadow: 0px 0px 12px 2px rgb(216, 218, 224);
  box-shadow: 0px 0px 12px 2px rgb(216, 218, 224);
  background-color: white;

}

.module-left {
  margin-right: 15%;
}

.module-right {
  margin-left: 15%;
}

.left {
  flex: 1;
}

.right {
  flex: 1;
  position: relative;
}

.learn-more {
  background-image: linear-gradient(to right, #4a4fff, #6ca0ff);
  border: none;
  padding: 15px 80px;
  border-radius: 5px;
  color: white;
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .products-price {
    color: #0A1329;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    margin-bottom: 24px;
  }

  .new-products {
    padding: 60px 24px 112px;
  }

  .all-products {
    padding: 60px 24px 112px;
  }
}

/* Mobile */
@media only screen and (max-width: 640px) {
  .new-products {
    padding: 60px 24px 112px;
  }

  .new-products br {
    display: none;
  }

  .new-products-list {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 32px;
  }

  .all-products {
    padding: 60px 24px 112px;
  }

  .all-products br {
    display: none;
  }

  .all-products-list {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}