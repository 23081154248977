.auth {
  display: flex;  
  background-color: #F3F5FC;    
  height: 100vh;
}

.auth-image {
  height: 480px;
  margin-top: 100px;
}

.auth-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
}

.signin-form {
  display: flex;
  height: auto;
  width: 340px;
  flex-direction: column;
  background-color: #fff;
  border-radius: 30px;
  margin: 44px 0px;
  padding: 42px;
}

.signin-form-modal {
  display: flex;
  width: 340px;
  flex-direction: column;
  background-color: #fff;
  border-radius: 30px;
  margin: 44px 0px;
  padding: 42px;
}

.loading-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 340px;
  background-color: #fff;
  border-radius: 30px;
  margin: 44px 0px;
  padding: 42px;
}

.loading-modal img {
  width: 76px;
}

.signup-form {
  display: flex;
  height: auto;
  width: 340px;
  flex-direction: column;
  background-color: #fff;
  border-radius: 30px;
  margin: 12px 0px;
  padding: 24px 42px;
}

.signup-form-modal {
  display: flex;
  width: 340px;
  flex-direction: column;
  background-color: #fff;
  border-radius: 30px;
  margin: 12px 0px;
  padding: 42px;
}

.auth-logo {
  display: flex;  
  color: #0A1329;
  align-items: center;  
}

.auth-logo > img {
  margin-right: 16px;
}

.auth-title {
  color: #0A1329;
  font-weight: 500;
  font-size: 24px;
  margin: 28px 0px;
}

.auth-title-modal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
}

.auth-title-modal > h1 {
  color: #0A1329;
  font-weight: 500;
  font-size: 24px;
}

.auth-title-modal > button {
  height: 24px;
  background-color: #fff;
  border: 0px;
  cursor: pointer;
}

.auth-title-modal > button > svg {
  height: 24px;
  color: #0A1329;
  opacity: 0.55;
}

.auth-or-text {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #D1D5DB;
  line-height: 0.1em;
  margin: 36px 0px;
}

.auth-or-text-span {
  font-weight: 300;
  color: #0A1329;  
  background-color: #fff;
  padding: 0px 8px;
}

.auth-input-form {
  display: flex;
  flex-direction: column;
  color: #0A1329;
  font-weight: 300;
  font-size: 18px;
}

.auth-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.auth-input {
  margin-top: 8px;
  padding: 10px 12px;
  border: 1px solid #c5c7ca;
  border-radius: 10px;
  color: #0A1329; 
  font-family: 'Rubik', sans-serif; 
  font-weight: 300;
  font-size: 18px;
  transition: 0.3s ease-in-out;
}

.auth-input:focus {
  border: 1px solid #306bc5;
  outline-width: 0;
}

.auth-error {
  display: flex;
  font-size: 16px;
  color: #fff;
  background-color: rgb(239 68 68);
  padding: 4px 12px;
  border-radius: 8px;
}

.auth-failed {
  display: flex;
  font-size: 16px;
  color: #fff;
  background-color: rgb(239 68 68);
  padding: 4px 12px;
  border-radius: 20px;
  margin-top: 8px;
}

.auth-button {
  background-color: #0A1329;
  color: #fff;
  font-weight: 300;
  font-family: 'Rubik', sans-serif; 
  font-size: 18px;
  border-radius: 50px;
  border: 0px;
  padding: 14px 0px;
  margin-top: 12px;
  transition: 0.3s ease-in-out;
}

.auth-button:hover {
  cursor: pointer;
  background-color: #050c1d;
}

.auth-change {
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  margin-top: 24px;
}

.auth-change > a {
  color: #2D7AF6;
}

.auth-change > span {
  color: #2D7AF6;
  cursor: pointer;
}

/* Mobile */
@media only screen and (max-width: 1024px) {
  .auth {
    display: flex;  
    background-color: #FFF;    
    height: 100vh;
    justify-content: center;
    /* align-items: center; */
  }

  .auth-image {
    display: none;
  }

  .signin-form {     
    border-radius: 0px;
    margin: 0px;
  }

  .signup-form {
    border-radius: 0px;
    margin: 0px;    
  }

  .auth-change {    
    padding-bottom: 24px;
  }
}