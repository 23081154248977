.footer {
  display: flex;
  justify-content: space-between;
  background-color: #0A1329;
  color: #fff;
  padding: 72px 100px;
  margin-inline: 30px;
  margin-bottom: 30px;
  border-radius: 20px;
}

.company-profile > a > img {
  display: block;
  margin-right: 16px;
  width: 68px;
}

.company-profile > a {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 24px;
}

.company-profile > p {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 24px;
}

.social-button {
  width: 44px;
  margin-right: 16px;
}

.company-office {
  padding-right: 52px;
}

.company-office > h1 {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 32px;
}

.company-office > p {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 12px;
}

@media only screen and (max-width: 1000px) {
  .footer {        
    padding: 100px 24px;
  }
}

/* Mobile */
@media only screen and (max-width: 640px) {
  .footer {    
    flex-direction: column;
    padding: 100px 24px;
  }

  .company-office {
    margin-top: 60px;
  }
}