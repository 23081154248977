.gallery {
    .Selection div {
        background-color: white;
        height: 100%;
        padding-bottom: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    
    thead th div {
        background-color: white;
        height: 100%;
        padding-top: 15px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    
    tbody td div {
        background-color: white;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    thead th {
        padding: 0px 10px;
        height: 1px;
    }
    
    tbody td {
        padding: 0px 10px;
        height: 1px;
    }
    
    
    .Category {
        color: rgb(20, 55, 152);
    }
    
    .Parameter {
        padding-left: 30px;
        font-weight: normal;
        color: rgb(48, 48, 48);
    }
    
    .Select {
        border: none;
        border-radius: 5px;
        transition: 0.5s;
        color: white;
        padding: 10px 40px;
        cursor: pointer;
        font-size: 16px;
        margin-block: 10px;
    }
    
    .Imbiss-Select {
        background-image: linear-gradient(45deg, #06529C, #01E296);
    }
    
    .Restaurant-Select {
        background-image: linear-gradient(45deg, #b72525, #ceab2d);
    }
    
    .Complete-Select {
        background-image: linear-gradient(45deg, #8e3585, #4f0639);
    }
    
    .Feedboost {
        font-size: 18px;
        margin: 0px;
        font-weight: lighter;
    }
    
    .Name {
        font-weight: bold;
        font-size: 50px;
        margin: 0px;
    }
    
    .Imbiss {
        background: -webkit-linear-gradient(45deg, #06529C, #01E296);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .Restaurant {
        background: -webkit-linear-gradient(45deg, #b72525, #ceab2d);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .Complete {
        background: -webkit-linear-gradient(45deg, #8e3585, #4f0639);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    
    body {
        padding: 5%;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        background-color: #F3F7F9;
    }
    
    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom:1em;
      table-layout: fixed;
    
    
    }
    
    th, td {
        padding: 15px 20px;
        white-space: pre;
    }
    
    thead th,
    tbody td {
        text-align: center;
    }
    
    tbody th {
        text-align: left;
    }
      
    thead {
    }
}