* {
  margin: 0;
  padding: 0;
}

html {
  background-color: #F3F5FC;
}

body {
  font-family: 'Rubik', sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;  
}

textarea {
  resize: none;
}